<template>
  <v-card :height="test" flat style="border-radius: 12px; overflow-y: auto">
    <v-card-text class="d-flex pa-2">
      <div class="d-flex align-center">
        <div class="mx-2">
          <span class="d-flex align-left font-weight-bold main_black--text"
            >Appointments</span
          >
          <span class="d-flex align-left">{{
            new Date() | dateformat("date_huge", userTimeZone)
          }}</span>
        </div>
      </div>
      <v-spacer />
      <v-card
        flat
        color="#E8E8E8"
        style="border-radius: 12px"
        class="mx-2 px-2 py-1"
      >
        <span>
          {{
            new Date().toLocaleString("en-us", {
              weekday: "long",
            })
          }} </span
        ><br />
        <span
          class="font-weight-bold main_black--text"
          style="font-size: 28px"
          >{{ new Date().getDate() }}</span
        ><br />
        <span
          >{{ getMonthName(new Date().getMonth()) }},
          {{ new Date().getFullYear() }}</span
        >
      </v-card>
    </v-card-text>
    <v-card-text class="my-n3" v-for="(item, i) in filterAppointments" :key="i">
      <v-row>
        <v-col cols="3">
          <v-card
            height="100%"
            flat
            style="border-radius: 12px"
            color="#E8E8E8"
            class="px-2 d-flex align-center justify-center"
          >
            <span class="font-weight-bold main_black--text">
              {{
                item.appointmentDateTime
                  | dateformat("time_am_pm", item.appointmentTimezone)
              }}</span
            >
          </v-card>
        </v-col>
        <v-col cols="9">
          <v-card
            height="100%"
            flat
            style="border-radius: 12px"
            color="#E8E8E8"
            class="px-2 py-1"
          >
            <div class="d-flex align-center">
              <div>
                <span
                  class="d-flex align-left font-weight-bold main_black--text"
                  >{{ item.appointmentTitle }}</span
                >
                <span class="d-flex align-left">{{
                  item.appointmentDescription
                }}</span>
                <span
                  class="d-flex align-left font-weight-bold main_black--text"
                  >{{
                    item.appointmentDateTime
                      | dateformat("time_am_pm", item.appointmentTimezone)
                  }}
                  -
                  {{
                    DateTime.fromISO(item.appointmentDateTime, {
                      zone: "utc",
                    }).setZone(item.appointmentTimezone)
                      | addTime({
                        minutes: item.appointmentPlannedDurationInMin,
                      })
                      | dateformat("time_am_pm", item.appointmentTimezone)
                  }}</span
                >
              </div>
              <v-spacer />
              <!-- <v-btn icon @click="infoClick(item)"
                ><v-icon>mdi-information-outline</v-icon></v-btn
              > -->
              <v-card
                flat
                color="primary"
                class="pa-2 mx-2 d-flex align-center justify-center"
                @click="goToRoom(item)"
              >
                <img height="24px" width="24px" :src="videoIcon" />
              </v-card>
            </div>
          </v-card>
        </v-col>
      </v-row>
    </v-card-text>
    <AppointmentDetailsDialog
      v-if="dialog"
      :dialog="dialog"
      :details="appointmentDetails"
      @close="dialog = false"
    />
    <Alert :title="title" :status="snackStatus" :color="snackColor"></Alert>
  </v-card>
</template>
<script>
import { mapState } from "vuex";
import { DateTime } from "luxon";
import AppointmentDetailsDialog from "@/views/dialogs/AppointmentDetailsDialog.vue";
import Alert from "@/views/Client/components/Alert.vue";
import { videocall_white } from "@/assets";
var Vue = null;
export default {
  components: { AppointmentDetailsDialog, Alert },
  props: ["userTimeZone"],
  data() {
    return {
      dialog: false,
      appointmentDetails: null,
      DateTime: DateTime,
      videoIcon: videocall_white,
      snackStatus: false,
      title: "",
      snackColor: "",
      noDevices: false,
    };
  },
  computed: {
    ...mapState({
      appointmentsFromRepo: (state) => state.appointments.appointments,
    }),
    filterAppointments() {
      let today = new Date().toISOString();
      let todayAppointments = this.appointmentsFromRepo?.filter((a) => {
        return a.appointmentDateTime.split("T")[0] === today.split("T")[0];
      });
      let sortedAppointments = todayAppointments?.sort((obj1, obj2) => {
        let obj1Temp = new Date(obj1.appointmentDateTime);
        let obj2Temp = new Date(obj2.appointmentDateTime);
        return obj1Temp - obj2Temp;
      });
      return sortedAppointments;
      // return this.appointmentsFromRepo;
    },
    test() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return window.innerHeight - 500;
        case "sm":
          return window.innerHeight - 500;
        case "md":
          return window.innerHeight - 200;
        case "lg":
          return window.innerHeight - 220;
        case "xl":
          return window.innerHeight - 220;
        default:
          return 0;
      }
    },
  },
  async mounted() {
    await this.getAppointments();
  },
  methods: {
    async goToRoom(item) {
      Vue = this;
      Vue.snackStatus = false;
      await navigator.mediaDevices
        .getUserMedia({ audio: true })
        .then(function () {
          Vue.noDevices = false;
        })
        .catch(function (err) {
          Vue.snackStatus = true;
          Vue.title = err;
          Vue.snackColor = "deep-orange darken-4";
          Vue.noDevices = true;
        });
      if (Vue.noDevices) {
        Vue = null;
        return;
      }
      Vue = null;
      console.log("cktau",item)
      let routeData = this.$router.resolve({
        name: "Room6",
        params: {
          id: `${item.videoRoomId}`,
          userId: `${item.patientId}`,
          appointmentId: item.appointmentId ? item.appointmentId : item.id,
        },
      });
      window.open(routeData.href, "_blank");
      // this.$router.push({
      //   name: "Room6",
      //   params: {
      //     id: `${item.videoRoomId}`,
      //     userId: `${item.patientId}`,
      //     patientId: item.patientId,
      //   },
      // });
    },
    // infoClick(item) {
    //   this.appointmentDetails = item;
    //   this.dialog = true;
    // },
    getMonthName(i) {
      var a = [
        { name: "Jan" },
        { name: "Feb" },
        { name: "Mar" },
        { name: "Apr" },
        { name: "May" },
        { name: "Jun" },
        { name: "Jul" },
        { name: "Aug" },
        { name: "Sep" },
        { name: "Oct" },
        { name: "Nov" },
        { name: "Dec" },
      ];
      return a[i].name;
    },
    async getAppointments() {
      await this.$store.dispatch("appointments/getAppointments");
    },
  },
};
</script>
<style>
::-webkit-scrollbar {
  width: 0px !important;
}
</style>