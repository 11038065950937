<template>
  <div>
    <CountersCard :items="items" />
    <v-row class="mx-2">
      <v-col
        cols="12"
        :xl="roleName === 'DOCTOR' ? '9' : '12'"
        :lg="roleName === 'DOCTOR' ? '8' : '12'"
        md="12"
      >
        <NotificationsCard
          :type="'dashboard'"
          :items="items"
          :itemsPerPage="11"
          :loading="loading"
          :userTimeZone="userTimeZone"
          @filter="filterData"
          @rerender="getMyNotifications"
        />
      </v-col>
      <v-col
        v-if="roleName === 'DOCTOR'"
        class="pt-0"
        cols="12"
        xl="3"
        lg="4"
        md="12"
      >
        <AppointmentsCard :userTimeZone="userTimeZone" />
      </v-col>
    </v-row>
  </div>
</template>
<script>
import { mapGetters, mapState } from "vuex";
import {
  abnormalNotification,
  allNotification,
  missed_notification_white,
  messages_notification_white,
  appointments_white,
} from "@/assets/";
import AppointmentsCard from "./AppointmentsCard.vue";
import CountersCard from "./CountersCard.vue";
import NotificationsCard from "./NotificationsCard.vue";
export default {
  components: { AppointmentsCard, CountersCard, NotificationsCard },
  data() {
    return {
      loading: true,
    };
  },
  computed: {
    ...mapState({
      userDetails: (state) => state.authentication.userData,
      notifications: (state) => state.notifications.allNotifications,
      abnormalNotifications: (state) =>
        state.notifications.abnormalMeasurements,
      reminders: (state) => state.notifications.reminders,
      missed: (state) => state.notifications.missMeasurements,
      chatNotifications: (state) => state.notifications.chatNotifications,
      appointmentRequests: (state) => state.notifications.appointmentRequests,
    }),
    ...mapGetters({ roleName: "authentication/getRole" }),
    items() {
      var items =
        this.roleName === "DOCTOR"
          ? [
              {
                text: "All Notifications",
                img: allNotification,
                cardColor: "#6600FF",
                read: this.notifications.filter((a) => {
                  return a.IsRead;
                }).length,
                all: this.notifications.length,
              },
              {
                text: "Abnormal",
                img: abnormalNotification,
                cardColor: "#D30202",
                read: this.abnormalNotifications.filter((a) => {
                  return a.IsRead;
                }).length,
                all: this.abnormalNotifications.length,
              },
              {
                text: "Missed",
                img: missed_notification_white,
                cardColor: "#FF931E",
                read: this.missed.filter((a) => {
                  return a.IsRead;
                }).length,
                all: this.missed.length,
              },
              {
                text: "Appointment Requests",
                img: appointments_white,
                cardColor: "#006837",
                read: this.appointmentRequests.filter((a) => {
                  return a.IsRead;
                }).length,
                all: this.appointmentRequests.length,
              },
            ]
          : [
              {
                text: "All Notifications",
                img: allNotification,
                cardColor: "#6600FF",
                read: this.notifications.filter((a) => {
                  return a.IsRead;
                }).length,
                all: this.notifications.length,
              },
              {
                text: "Abnormal",
                img: abnormalNotification,
                cardColor: "#D30202",
                read: this.abnormalNotifications.filter((a) => {
                  return a.IsRead;
                }).length,
                all: this.abnormalNotifications.length,
              },
              {
                text: "Missed",
                img: missed_notification_white,
                cardColor: "#FF931E",
                read: this.missed.filter((a) => {
                  return a.IsRead;
                }).length,
                all: this.missed.length,
              },
              {
                text: "Messages",
                img: messages_notification_white,
                cardColor: "#3A3A3A",
                read: this.chatNotifications.filter((a) => {
                  return a.IsRead;
                }).length,
                all: this.chatNotifications.length,
              },
            ];
      return items;
    },

    userTimeZone() {
      return this.userDetails?.userTimezone;
    },
  },
  mounted() {
    this.getMyNotifications();
  },
  methods: {
    async filterData(item) {
      this.loading = true;
      await this.$store.dispatch("notifications/ALL_NOTIFICATIONS", item);
      this.loading = false;
    },
    async getMyNotifications() {
      this.loading = true;
      await this.$store.dispatch("notifications/ALL_NOTIFICATIONS", "TODAY");
      this.loading = false;
    },
  },
};
</script>