<template>
  <v-row class="mx-2">
    <v-col v-for="(item, i) in items" :key="i">
      <v-card flat style="border-radius: 12px">
        <v-card-text class="d-flex pa-3">
          <v-card
            flat
            :color="item.cardColor"
            class="px-2 d-flex align-center justify-center"
          >
            <img height="24px" width="24px" :src="item.img" />
          </v-card>
          <div class="d-flex align-center justify-center">
            <div class="mx-2">
              <span
                class="d-flex align-left mb-n2"
                style="
                  font-size: 12px;
                  display: inline-block;
                  white-space: nowrap;
                  overflow: hidden !important;
                  text-overflow: ellipsis;
                "
                >{{ item.text }}</span
              >
              <span class="d-flex align-left"
                >{{ item.read }}/{{ item.all }}</span
              >
            </div>
          </div>
          <v-spacer />
          <!-- <v-icon>mdi-account-outline</v-icon> -->
          <v-progress-circular
            :rotate="-90"
            :size="42"
            :width="10"
            :value="calc(item)"
            color="primary"
            style="ma-n2"
          >
            <!-- <span v-if="calc(item) !== ''">{{ calc(item) }}%</span> -->
          </v-progress-circular>
        </v-card-text>
      </v-card>
    </v-col>
  </v-row>
</template>
<script>
import { mapState } from "vuex";

export default {
  props: ["items"],
  computed: {
    ...mapState({
      userDetails: (state) => state.authentication.userData,
      notifications: (state) => state.notifications.allNotifications,
      abnormalNotifications: (state) =>
        state.notifications.abnormalMeasurements,
      reminders: (state) => state.notifications.reminders,
      missed: (state) => state.notifications.missMeasurements,
      chatNotifications: (state) => state.notifications.chatNotifications,
    }),
  },
  methods: {
    calc(item) {
      if (item.all === 0) {
        return 0;
      }
      return Math.round((item.read / item.all) * 100);
    },
  },
};
</script>